import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Camion from '../../assets/images/family_icons/icon_camion.svg'
import Coche from '../../assets/images/family_icons/icon_coche.svg'
import Moto from '../../assets/images/family_icons/icon_moto.svg'
import Tractor from '../../assets/images/family_icons/icon_tractor.svg'
import ButtonLink from '../../components/buttons/ButtonLink'
import Layout from '../../components/Layout'
import { SeoData } from '../../../../src/common/types/Seo'
import TabsAux from '../../components/tabsAux/TabsAux'
import Title from '../../components/Title'
import { MenuPrincipal } from '../../../specific/constants/menu'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { t, textWithLocale } from '../../../common/i18n'
import route from '../../utils/route'
import styles from './promociones.module.scss'

const tabsArray = (data: Data) => {
  return data.familiasVehiculos.nodes.map((node) => {
    let icon
    switch (node.label) {
      case 'camion':
        icon = <Camion className={styles.icon} />
        break
      case 'moto':
        icon = <Moto className={styles.icon} />
        break
      case 'agricola':
        icon = <Tractor className={styles.icon} />
        break
      case 'coche':
        icon = <Coche className={styles.icon} />
        break
      default:
        throw new Error(`Tipo de oferta desconocido ${node.label}`)
    }

    return {
      tab: (
        <React.Fragment>
          {icon}
          <span>{node[textWithLocale('label')]}</span>
        </React.Fragment>
      ),
      label: node.label,
      route: route(`promociones.vehiculo_${node.label}`),
    }
  })
}

interface ContenidoProps {
  data: Data
  path: string
}

/**
 * We customize the global title depending on which vehicle we chose
 * @param path
 */
function getPageTitle(path) {
  if (path.includes(route('promociones.vehiculo_coche'))) {
    return `${t('promociones.promociones')}${t('promociones.coche')}`
  }
  if (path.includes(route('promociones.vehiculo_moto'))) {
    return `${t('promociones.promociones')}${t('promociones.moto')}`
  }
  if (path.includes(route('promociones.vehiculo_camion'))) {
    return `${t('promociones.promociones')}${t('promociones.camion')}`
  }
  if (path.includes(route('promociones.vehiculo_agricola'))) {
    return `${t('promociones.promociones')}${t('promociones.agricola')}`
  }
}

const altImagesPromotions = (text) => {
  if (text) {
    return `oferta: ${text.replace(/[-]/g, ' ').replace(/[//]/g, '')}`
  }
  return ''
}

const Contenido = ({ data, path }: ContenidoProps) => {
  const tabs = tabsArray(data)
  return (
    <div className={styles.promotions_page_background}>
      <Title text={getPageTitle(path)} />
      <TabsAux tabsArray={tabs} className={styles.tab_title_with_logo} />

      <div className={styles.promotions_area}>
        {data.promociones && data.promociones.site_promocions.length ? (
          data.promociones.site_promocions
            .filter((item) => item.grid === 1)
            .map((promocion, index) => (
              <div key={index} className={styles.promotions_grid_box}>
                <div className={styles.promotion_box}>
                  <picture>
                    <source
                      srcSet={promocion[textWithLocale('urlImage')]}
                      media="(max-width: 1279px)"
                    />
                    <img
                      src={promocion[textWithLocale('mini_urlImage')]}
                      alt={altImagesPromotions(
                        promocion[textWithLocale('seo_url')]
                      )}
                    />
                  </picture>

                  <div className={styles.promotion_right_content}>
                    <div className={styles.promotion_texts}>
                      <div className={styles.promotion_title}>
                        <span>{promocion[textWithLocale('descripcion')]}</span>
                      </div>
                      <div className={styles.promotion_summary}>
                        <span>{promocion[textWithLocale('resumen')]}</span>
                      </div>
                    </div>
                    <ButtonLink
                      to={`${t(
                        `route.promociones.detalle_${data.promociones.familia_vehiculo}`,
                        {
                          id_site_promocion:
                            promocion[textWithLocale('seo_url')],
                        }
                      )}`}>
                      {t('promociones.see_offer').toUpperCase()}
                    </ButtonLink>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <div className={styles.none_promotions}>
            <FormattedMessage id="promociones.no_promociones" />
          </div>
        )}
      </div>
      {data.allPromoFin.nodes.length > 0 && (
        <React.Fragment>
          <p className={styles.titleDisabledPromosTitle}>
            <FormattedMessage id="promociones.promociones_finalizadas" />
          </p>
          <ul className={styles.disabledPromosTitle}>
            {data.allPromoFin.nodes.map((promo, index) => (
              <li key={index}>
                <a href={promo[textWithLocale('url')]}>
                  {promo[textWithLocale('texto')]}
                </a>
              </li>
            ))}
          </ul>
        </React.Fragment>
      )}
    </div>
  )
}

interface Props {
  data: Data
}
const PageTemplate = ({ data }: Props) => {
  useSelectedMenu(MenuPrincipal.OFERTAS)
  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.promociones'),
        },
      ]}>
      <Router>
        <Contenido data={data} path={route('promociones.vehiculo_coche')} />
        <Contenido data={data} path={route('promociones.vehiculo_moto')} />
        <Contenido data={data} path={route('promociones.vehiculo_camion')} />
        {tabsArray(data).find((tab) => tab.label === 'agricola') && (
          <Contenido
            data={data}
            path={route('promociones.vehiculo_agricola')}
          />
        )}
      </Router>
    </Layout>
  )
}

export default PageTemplate

interface Promocion {
  urlImage_ca: string
  urlImage_es: string
  mini_urlImage_ca: string
  mini_urlImage_es: string
  texto_ca: string
  texto_es: string
  id_site_promocion: number
  descripcion_ca: string
  descripcion_es: string
  final_validez: ISO8601DateString
  final_validez_aplicable: ISO8601DateString
  inicio_validez: ISO8601DateString
  resumen_ca: string
  resumen_es: string
  grid: number
}

interface PromoFin {
  id_site_familia_vehiculo: number
  texto_es: string
  orden: number
  texto_ca: string
  texto_pt: string
  url_ca: string
  url_es: string
  url_pt: string
}
interface Data {
  promociones: {
    familia_vehiculo: string
    familia_vehiculo_ca: string
    familia_vehiculo_es: string
    site_promocions: Promocion[]
  }

  allPromoFin: {
    nodes: PromoFin[]
  }

  seoData: SeoData

  familiasVehiculos: {
    nodes: {
      label: string
      label_es: string
      label_ca: string
      label_pt: string
      value: number
    }[]
  }
}
export const pageQuery = graphql`
  query promocionesPageQuery($id_site_familia_vehiculo: Int!, $url: String!) {
    promociones(
      id_site_familia_vehiculo: { eq: $id_site_familia_vehiculo }
      site_promocions: { elemMatch: { grid: { eq: 1 } } }
    ) {
      familia_vehiculo
      familia_vehiculo_ca
      familia_vehiculo_pt
      familia_vehiculo_es
      site_promocions {
        urlImage_ca
        urlImage_es
        mini_urlImage_ca
        mini_urlImage_es
        mini_urlImage_pt
        texto_ca
        texto_es
        seo_url_ca
        seo_url_pt
        seo_url_es
        id_site_promocion
        descripcion_ca
        descripcion_es
        descripcion_pt
        fecha_fin
        final_validez_aplicable
        fecha_inicio
        resumen_ca
        resumen_pt
        resumen_es
        activo
        grid
      }
    }

    allPromoFin(
      filter: { id_site_familia_vehiculo: { eq: $id_site_familia_vehiculo } }
      sort: { order: ASC, fields: orden }
    ) {
      nodes {
        id_site_familia_vehiculo
        texto_es
        orden
        texto_ca
        texto_pt
        url_ca
        url_es
        url_pt
      }
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }

    familiasVehiculos: allFamiliaVehiculo {
      nodes {
        label
        label_ca
        label_es
        value
      }
    }
  }
`
