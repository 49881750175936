import { Link } from 'gatsby'
import React from 'react'
import styles from './tabsAux.module.scss'

interface TabDefinition {
  tab: string | JSX.Element
  route: string // Actually a path
}
interface Props {
  tabsArray: TabDefinition[]
  className?: string
}
const TabsAux = ({ tabsArray, className }: Props) => (
  <div className={styles.container}>
    {tabsArray.map(({ tab, route }) => (
      <Link
        to={route}
        key={route}
        activeClassName={`${styles.tab_active} ${className}`}>
        {tab}
      </Link>
    ))}
  </div>
)

export default TabsAux
